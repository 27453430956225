import { useState } from 'react'
import { ButtonColor } from 'global/types/enums'
import { Button } from 'shared/components/button'
import { Input } from 'shared/components/input'
import { decryptText, encryptText } from 'shared/services/crypto'

export const Crypto = () => {
  const [originalText, setOriginalText] = useState<string>('')

  const [encryptedText, setEncryptedText] = useState<string>('')

  const [encryptErrorMessage, setEncryptErrorMessage] = useState<string>('')

  const [decryptErrorMessage, setDecryptErrorMessage] = useState<string>('')

  const encrypt = () => {
    try {
      const encryptedText = encryptText(originalText)

      setEncryptedText(encryptedText)
    } catch (error) {
      setEncryptErrorMessage(error?.toString() ?? 'Failed to encrypt.')
    }
  }

  const decrypt = () => {
    try {
      const originalText = decryptText(encryptedText)

      setOriginalText(originalText)
    } catch (error) {
      setDecryptErrorMessage(error?.toString() ?? 'Failed to decrypt.')
    }
  }

  return (
    <div className='px-4 pb-4'>
      <div className='mb-4'>
        <div className='flex flex-row gap-3 items-center mb-1'>
          <div className='flex-1'>
            <Input
              bordered
              placeholder='Text to encrypt'
              value={originalText}
              onChange={(text) => {
                setOriginalText(text)
                setEncryptErrorMessage('')
              }}
            />
          </div>
          <Button icon='arrow-down' label='Encrypt' color={ButtonColor.Primary} onClick={encrypt} />
        </div>
        <div className='text-sm h-5'>
          {encryptErrorMessage ? (
            <div className='text-danger'>{encryptErrorMessage}</div>
          ) : originalText.length ? (
            <div className='text-neutral'>{originalText.length} characters</div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div>
        <div className='flex flex-row gap-3 items-center mb-1'>
          <div className='flex-1'>
            <Input
              bordered
              placeholder='Text to decrypt'
              value={encryptedText}
              onChange={(text) => {
                setEncryptedText(text)
                setDecryptErrorMessage('')
              }}
            />
          </div>
          <Button icon='arrow-up' label='Decrypt' color={ButtonColor.Primary} onClick={decrypt} />
        </div>
        <div className='text-sm h-5'>
          {decryptErrorMessage ? (
            <div className='text-danger'>{decryptErrorMessage}</div>
          ) : encryptedText.length ? (
            <div className='text-neutral'>{encryptedText.length} characters</div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
