import { useRef, useState } from 'react'

export const CarImage = () => {
  const imageRef = useRef<HTMLImageElement>(null)
  const carImageRef = useRef<HTMLDivElement>(null)

  const [isImageHovered, setIsImageHovered] = useState<boolean>(false)

  const [isCarImageHovered, setIsCarImageHovered] = useState<boolean>(false)

  const hoverImage = () => setIsImageHovered(true)

  const unhoverImage = () => setIsImageHovered(false)

  const hoverCarImage = () => setIsCarImageHovered(true)

  const unhoverCarImage = () => setIsCarImageHovered(false)

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className='lg:w-64'>
        <h3 className='font-bold'>Specifications:</h3>

        <div>Car model: Santa FE</div>

        <div>Extension: PNG</div>

        <div>Background: Transparent</div>

        <div className={isImageHovered ? 'bg-disabled' : ''}>
          Resolution: {imageRef.current?.width} x {imageRef.current?.height}
        </div>

        <div className={isCarImageHovered ? 'bg-disabled' : ''}>
          Car size: {carImageRef.current?.offsetWidth} x {carImageRef.current?.offsetHeight}
        </div>

        <div>
          Horizontal padding:{' '}
          {((imageRef.current?.width ?? 0) - (carImageRef.current?.offsetWidth ?? 0)) / 2} x 2
        </div>

        <div>
          Vertical padding:{' '}
          {((imageRef.current?.height ?? 0) - (carImageRef.current?.offsetHeight ?? 0)) / 2} x 2
        </div>
      </div>

      <div className='flex flex-1 items-center justify-center bg-simple-pattern max-h-[600px]'>
        <div className='relative mx-4 my-16'>
          <div className='absolute -translate-x-1/2 -top-10 left-1/2 bg-white px-3 py-1 rounded-md text-center line-clamp-1'>
            Hover image to inspect
          </div>

          <img
            ref={imageRef}
            className={['bg-white bg-opacity-50', isImageHovered ? 'border-animate' : ''].join(' ')}
            src={'/unknown-car.png'}
            onMouseOver={hoverImage}
            onMouseLeave={unhoverImage}
          />

          <div
            ref={carImageRef}
            style={{
              width: (imageRef.current?.width ?? 0) * 0.91,
              height: (imageRef.current?.height ?? 0) * 0.78
            }}
            className={[
              'absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2',
              isCarImageHovered ? 'border-animate' : ''
            ].join(' ')}
            onMouseOver={hoverCarImage}
            onMouseLeave={unhoverCarImage}
          />
        </div>
      </div>
    </div>
  )
}
