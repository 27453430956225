import { useEffect, useState } from 'react'
import { getAppCheckToken } from 'global/firebase'
import type { KeyValueProps } from 'global/types/props'
import { Card } from 'shared/components/card'
import { CarImage } from './car-image'
import { PdfViewerDemo } from './pdf-viewer-demo'
import { Crypto } from './crypto'

export const DevCenter = () => {
  const [data, setData] = useState<KeyValueProps[]>([])

  useEffect(() => {
    document.title = ['DEV Center', 'Bluelink Subscription'].join(' - ')

    setAppCheckTokenData()
  }, [])

  const setAppCheckTokenData = async () => {
    const key = 'App Check Token'

    if (data.find((d) => d.key === key)) return

    const token = await getAppCheckToken()
    setData((prevValues) => [...prevValues, { key, value: token }])
  }

  return (
    <div className='p-4'>
      {data.map((d, id) => (
        <Card key={id + 1}>
          <h2 className='font-heading font-medium mb-4'>{d.key}</h2>
          <p className='text-sm break-words'>{d.value}</p>
        </Card>
      ))}

      <Card>
        <h2 className='font-heading font-medium mb-4'>Crypto - AES Encryption</h2>
        <Crypto />
      </Card>

      <Card>
        <h2 className='font-heading font-medium mb-4'>PDF Viewer</h2>
        <PdfViewerDemo />
      </Card>

      <Card>
        <h2 className='font-heading font-medium mb-4'>Car Image Sample</h2>
        <CarImage />
      </Card>

      <div className='text-neutral text-xs text-center my-4'>
        {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION}
      </div>
    </div>
  )
}
