import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch } from 'store/store'
import { api } from 'store/api'
import { L10N } from 'global/localization'
import { CONSTANT } from 'global/constant'
import { LayoutType, ResultType } from 'global/types/enums'
import { Layout } from 'shared/components/layout'
import { formatDate } from 'shared/services/utils'
import { ResultContent } from 'shared/components/result-content'

const TYPE_QPARAM_KEY = 't'

const DATA_QPARAM_KEY = 'd'

export const Result = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()

  const [type, setType] = useState<ResultType>()

  const [data, setData] = useState<ResultData>()

  useEffect(() => {
    setType(Number(searchParams.get(TYPE_QPARAM_KEY)))

    const data = searchParams.get(DATA_QPARAM_KEY)
    if (data) setData(JSON.parse(data))
  }, [searchParams])

  const getContent = () => {
    switch (type) {
      case ResultType.AutoRenewalStop:
        return data?.date ? (
          <ResultContent
            image='/stop-payment.png'
            title={L10N.LABEL.AUTO_RENEWAL_STOPPED}
            desc={
              L10N.formatString(
                L10N.MESSAGE.AUTO_RENEWAL_STOPPED_DESC,
                formatDate(data.date)
              ) as string
            }
            actionText={L10N.LABEL.BACK_TO_HOME_PAGE}
            onAction={() => onAction()}
          />
        ) : (
          <></>
        )

      case ResultType.PaymentSuccess:
        return (
          <ResultContent
            image='/icon-success.png'
            title={L10N.LABEL.PAYMENT_SUCCESSFUL}
            desc={L10N.MESSAGE.PAYMENT_SUCCESSFUL_DESC}
            actionText={L10N.LABEL.BACK_TO_BLUELINK}
            onAction={() => onAction(true)}
          />
        )

      case ResultType.PaymentFailed:
        return (
          <ResultContent
            image='/icon-failed.png'
            title={L10N.LABEL.PAYMENT_FAILED}
            desc={L10N.MESSAGE.PAYMENT_FAILED_DESC}
            actionText={L10N.LABEL.BACK_TO_BLUELINK}
            onAction={() => onAction(true)}
          />
        )

      default:
        return null
    }
  }

  const onAction = (toBluelinkHome = false) => {
    dispatch(api.util.invalidateTags([CONSTANT.CAR_TAG]))

    // if (toBluelinkHome) return window.open('mbluelinkid://homepage')
    // if (toBluelinkHome) return openExternalLink('https://bluelinkid.page.link/openhome')

    if (toBluelinkHome) {
      window.Android?.exitPage()
      window.webkit?.messageHandlers.exitPage.postMessage(true)

      if (!window.Android && !window.webkit) navigate('/home')

      return
    }

    navigate('/home')
  }

  return (
    <Layout title={L10N.LABEL.RESULT} type={LayoutType.Fullscreen}>
      <div className='h-full animate-fadeIn flex items-center justify-center'>{getContent()}</div>
    </Layout>
  )
}

interface ResultData {
  date: string
}
