import CryptoJS from 'crypto-js'

export const encryptText = (originalText: string): string => {
  if (!process.env.REACT_APP_CRYPTO_SECRET_KEY) {
    throw new Error('Missing secret key.')
  }

  const encryptedText = CryptoJS.AES.encrypt(
    originalText,
    process.env.REACT_APP_CRYPTO_SECRET_KEY
  ).toString()

  return encryptedText
}

export const decryptText = (encryptedText: string): string => {
  if (!process.env.REACT_APP_CRYPTO_SECRET_KEY) {
    throw new Error('Missing secret key.')
  }

  const bytes = CryptoJS.AES.decrypt(encryptedText, process.env.REACT_APP_CRYPTO_SECRET_KEY)
  const originalText = bytes.toString(CryptoJS.enc.Utf8)

  return originalText
}
